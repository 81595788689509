import React, {useState} from "react";
import {css} from "@emotion/react"
import { Helmet } from "react-helmet";

const QUESTIONS = [
  {
    prompt: "You are at your friend’s wedding. What do you do?",
    img: "wedding.jpeg",
    options: [
      {text: "Give a great speech talking about how amazing he is", value: 0},
      {text: "Force his wife to marry you instead, then divorce her tomorrow", value: 20},
      {text: "Show off your dancing skills", value: 5},
    ]
  },
  {
    prompt: "You’re at a dinner party and start laughing loudly. People ask why you’re laughing. What do you say?",
    img: "dinner.jpeg",
    options: [
      {text: '"I’m just so happy to be around all of you amazing people."', value: 0},
      {text: "Just keep laughing and don’t explain", value: 5},
      {text: '“I just realized that I only need to say the word and I could have you all executed haha.”', value: 20},
    ]
  },
  {
    prompt: "You are on your way to invade Britain and have reached the English Channel. What do you do?",
    img: "britain.jpeg",
    options: [
      {text: "Order your men to pick up sea shells from the beach and declare that you’ve won the war against the sea", value: 20},
      {text: "Take a bath in the sea", value: 5},
      {text: "Sail across and conquer!", value: 0},
    ]
  },
  {
    prompt: "Your advisor says you should calm down on calling yourself a god. What do you do?",
    img: "god.jpeg",
    options: [
      {text: "Build temples dedicated to worshipping you, and replace existing statues of gods with statues of you", value: 20},
      {text: "Take his advice and humble yourself", value: 0},
      {text: "Execute him for treason", value: 5},
    ]
  },
  {
    prompt: "The treasury is running low on money because of your lavish spending. What do you do?",
    img: "money.jpeg",
    options: [
      {text: "Cut back on spending", value: 0},
      {text: "Increase taxes", value: 5},
      {text: "Arrest random people for treason and steal their property", value: 20},
    ]
  },
]

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const RomanEmperorSimulator = () => {
  const [questionIndex, setQuestionIndex]= useState(0)
  const [prob, setProb] = useState(0)
  const [diff, setDiff] = useState(0)
  const chances = getRandomInt(100)
  console.log(chances)
  const wouldBeKilled = prob > chances

  const onSelectOption = (option) => {
    setDiff(option.value)
    setProb(prob + option.value)
    setQuestionIndex(questionIndex+1)
  }

  return (
    <div css={css`
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 18px;
      padding-bottom: 50px;
    `}>
      <h1 css={css`
        margin-bottom: 10px;
        text-align: center;
      `}>Roman Emperor Simulator</h1>
      <img css={css`
        height: 100px;

      `} src="/empire.png" alt="Roman Empire Flag"></img>

      <h3>Your goal is to be assassinated</h3>
      <label for="file">Assassination Probability ({prob}%)</label>
<progress id="file" value={prob} max="100"> {prob}% </progress>
<div css={css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
`}>

  {questionIndex < QUESTIONS.length ? (
    <>
      <h2 css={css`
        text-align: center;
      `}>{QUESTIONS[questionIndex].prompt}</h2>

  <img css={css`
    max-height: 100px;
    margin-top: 15px;
  `} src={QUESTIONS[questionIndex].img} alt="something funny"></img>
  <div css={css`
    display: flex;
    flex-direction: column;
    align-items: center;
  
  `}>
  {QUESTIONS[questionIndex].options.map((option) => (
    <button css={css`
    margin-top: 20px;
    font-size: 18px;
    padding: 6px;
    cursor: pointer;
    width: 350px;
    word-wrap: break-word;
    `}
    onClick={() => onSelectOption(option)}
    >
     { option.text}
      </button>
  ))}
  </div>
  </>
  ) : wouldBeKilled ?  (
    <div css={css`text-align: center;`}>
      <h2>You have successfully angered the Romans</h2>
      <img css={css`
        max-width: 400px;
      `} src="/deadcaligula.jpeg"/>
      <h3>Enjoy the afterlife.</h3>
    </div>
  ) : <div css={css`text-align: center;`}>
      <h2>Mission Failed: The Romans let you live</h2>
      <img css={css`
       max-width: 400px;
      `} src="/caligula.jpeg"/>
      <h3>Caesar would be proud of you.</h3>
    </div>}

</div>
    </div>
  )
}
  export default RomanEmperorSimulator;